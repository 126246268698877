import imageList from './images.js';
import { Gallery } from './gallery.js';

let backdropEl = document.querySelector('.nav-wrapper');

const populatePortfolio = (selector = '#portfolio', imageList = []) => {
    const portfolioEl = document.querySelector(selector);
    if (portfolioEl === null) return;

    new Gallery(portfolioEl, imageList, { modalRoot: '#modal-root' }).buildGallery();
};

const toggleMenu = (state = '') => {
    if (!backdropEl.classList.contains('is-open') || 'open' === state) {
        backdropEl.classList.add('is-open');
        backdropEl.addEventListener('click', parseClick);
        document.addEventListener('keydown', parseKeydown);
    } else {
        backdropEl.classList.remove('is-open');
        backdropEl.removeEventListener('click', parseClick);
        document.removeEventListener('keydown', parseKeydown);
    }
};

const parseKeydown = event => {
    if (event.code === 'Escape') toggleMenu('close');
};

const parseClick = event => {
    if (event.target === backdropEl) toggleMenu('close');
    if (event.target.closest('a')?.classList.contains('nav-link')) toggleMenu('close');
};

const menuClickHandler = event => {
    if (event.target !== backdropEl && backdropEl.classList.contains('is-open')) {
        backdropEl.classList.remove('is-open');
        backdropEl.removeEventListener('click', menuClickHandler);
    }
};

function main() {
    backdropEl = document.querySelector('.nav-wrapper');

    document.querySelector('.nav-burger-btn').addEventListener('click', toggleMenu);

    const imageMap = imageList.map(({ original, preview, tall }) => ({
        original: `/portfolio/${original}`,
        preview: `/portfolio/${preview}`,
        tall,
    }));

    populatePortfolio('#portfolio', imageMap);
}

window.addEventListener('load', main);
