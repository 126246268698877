export { Gallery };

class Gallery {
	onGalleryClickBinder = this.onGalleryClick.bind(this);
	onKeyboardClickBinder = this.onKeyboardClick.bind(this);
	onModalClickBinder = this.onModalClick.bind(this);
	onModalTouchStartBinder = this.onModalTouchStart.bind(this);
	onModalTouchEndBinder = this.onModalTouchEnd.bind(this);

	constructor(galleryEl, galleryItems = [], options = {}) {
		this.galleryEl = galleryEl;
		this.galleryItems = galleryItems;

		const defaultOptions = {
			modalRoot: '#modal-root',
			modalOpenClass: 'active',
		};

		for (const [key, value] of Object.entries({ ...defaultOptions, ...options })) {
			this[key] = value;
		}

		this.modalEl = document.querySelector(this.modalRoot);
	}

	buildGallery() {
		if (!this.galleryEl) {
			console.error('No gallery root defined. Can\'t build gallery.');
			return;
		}

		this.galleryEl.innerHTML = this.galleryItems.map(this.createImageCardMarkup).join('');
		this.galleryEl.addEventListener('click', this.onGalleryClickBinder);
	}

	showModal(src) {
		if (this.isModalVisible()) {
			this.closeModal();
		}

		this.curModalSrc = src;
		this.curModalIdx = this.findCurrentSrcIndex(src);
		this.modalEl.innerHTML = `<img src="${src}" alt="" class="modal-img">`;
		this.galleryEl.addEventListener('keydown', this.onKeyboardClickBinder);
		this.modalEl.addEventListener('click', this.onModalClickBinder);
		this.modalEl.addEventListener('touchstart', this.onModalTouchStartBinder);
		this.modalEl.addEventListener('touchend', this.onModalTouchEndBinder);
		this.modalEl.classList.add(this.modalOpenClass);
	}

	closeModal() {
		this.curModalSrc = null;
		this.curModalIdx = null;
		this.modalEl.classList.remove(this.modalOpenClass);
		this.modalEl.innerHTML = '';
		this.modalEl.removeEventListener('click', this.onModalClickBinder);
		this.galleryEl.removeEventListener('keydown', this.onKeyboardClickBinder);
	}

	onGalleryClick(e) {
		e.preventDefault();
		this.showModal(e.target.dataset.source);
	}

	onModalClick(e) {
		if (e.target === this.modalEl) {
			this.closeModal();
		}
	}

	onModalTouchStart(e) {
		const touch = e.touches[0];
		this.touchStartX = touch.pageX;
		this.touchStartY = touch.pageY;
	}

	onModalTouchEnd(e) {
		e.preventDefault();
		const touch = e.changedTouches[0];
		const xDiff = touch.pageX - this.touchStartX;
		const yDiff = touch.pageY - this.touchStartY;

		if (Math.abs(xDiff) > Math.abs(yDiff) && Math.abs(xDiff) > 20) {
			if (xDiff > 0) {
				this.modalShowPrev();
			} else {
				this.modalShowNext();
			}
		} else {
			this.closeModal();
		}

		this.touchStartX = null;
		this.touchStartY = null;
	}

	onKeyboardClick(e) {
		e.preventDefault();

		switch (e?.code) {
			case 'Escape':
				this.closeModal();
				return;

			case 'ArrowLeft':
			case 'ArrowDown':
				this.modalShowPrev();
				break;

			case 'ArrowRight':
			case 'ArrowUp':
			case 'Space':
				this.modalShowNext();
				break;
		}
	}

	modalShowNext() {
		this.showModal(this.galleryItems[this.curModalIdx === this.galleryItems.length - 1 ? 0 : this.curModalIdx + 1].original);
	}

	modalShowPrev() {
		this.showModal(this.galleryItems[this.curModalIdx === 0 ? this.galleryItems.length - 1 : this.curModalIdx - 1].original);
	}

	isModalVisible() {
		return this.modalEl.classList.contains(this.modalOpenClass);
	}

	findCurrentSrcIndex(src) {
		return this.galleryItems.findIndex(el => el.original === src);
	}

	createImageCardMarkup = ({ preview, original, tall } = {}) => {
		return `
        <li class="gallery__item ${tall ? 'tall' : ''}">
          <a class="gallery__link" href="${original}">
            <img
              class="gallery__image"
              src="${preview}"
              data-source="${original}"
			  loading="lazy"
              alt=""
            />
          </a>
        </li>`;
	};
}
