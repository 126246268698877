const imageList = [
	{ original: 'DSC_0041.jpg', preview: 'DSC_0041_thumb.jpg', tall: false },
	{ original: 'DSC_0165.jpg', preview: 'DSC_0165_thumb.jpg', tall: false },
	{ original: 'DSC_0242.jpg', preview: 'DSC_0242_thumb.jpg', tall: true },
	{ original: 'DSC_0244.jpg', preview: 'DSC_0244_thumb.jpg', tall: true },
	{ original: 'DSC_0292.jpg', preview: 'DSC_0292_thumb.jpg', tall: false },
	{ original: 'DSC_3276.jpg', preview: 'DSC_3276_thumb.jpg', tall: false },
	{ original: 'DSC_3378.jpg', preview: 'DSC_3378_thumb.jpg', tall: false },
	{ original: 'DSC_3446.jpg', preview: 'DSC_3446_thumb.jpg', tall: false },
	{ original: 'DSC_3447.jpg', preview: 'DSC_3447_thumb.jpg', tall: false },
	{ original: 'DSC_3502.jpg', preview: 'DSC_3502_thumb.jpg', tall: true },
	{ original: 'DSC_3843.jpg', preview: 'DSC_3843_thumb.jpg', tall: false },
	{ original: 'DSC_3908.jpg', preview: 'DSC_3908_thumb.jpg', tall: false },
	{ original: 'DSC_3926.jpg', preview: 'DSC_3926_thumb.jpg', tall: true },
	{ original: 'DSC_4239.jpg', preview: 'DSC_4239_thumb.jpg', tall: false },
	{ original: 'DSC_4270.jpg', preview: 'DSC_4270_thumb.jpg', tall: false },
	{ original: 'DSC_4316.jpg', preview: 'DSC_4316_thumb.jpg', tall: false },
	{ original: 'DSC_9151.jpg', preview: 'DSC_9151_thumb.jpg', tall: true },
	{ original: 'DSC_4407.jpg', preview: 'DSC_4407_thumb.jpg', tall: false },
	{ original: 'DSC_8169.jpg', preview: 'DSC_8169_thumb.jpg', tall: false },
	{ original: 'DSC_9776.jpg', preview: 'DSC_9776_thumb.jpg', tall: true },
	{ original: 'DSC_8711.jpg', preview: 'DSC_8711_thumb.jpg', tall: false },
	{ original: 'DSC_8751.jpg', preview: 'DSC_8751_thumb.jpg', tall: false },
	{ original: 'DSC_8910.jpg', preview: 'DSC_8910_thumb.jpg', tall: false },
	{ original: 'DSC_9116.jpg', preview: 'DSC_9116_thumb.jpg', tall: false },
	{ original: 'DSC_9884.jpg', preview: 'DSC_9884_thumb.jpg', tall: false },
];

export default imageList;
